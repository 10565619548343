<template>
  <base-card>
    <v-card-title>Personal Details</v-card-title>
    <v-card-text>
      <!-- Name-->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="name"
          name="Name"
          :initial-value="investor.name"
          :endpoint="endpoint"
          type="text"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Name -->
      <!-- Email-->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="email"
          name="Email"
          :initial-value="investor.email"
          :endpoint="endpoint"
          type="email"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Email -->
      <!-- Phone-->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="phone_number"
          name="Phone"
          :initial-value="investor.phone_number"
          :endpoint="endpoint"
          type="tel"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Phone -->
      <!-- Job Title -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="job_title"
          name="Job Title"
          :initial-value="investor.job_title"
          :endpoint="endpoint"
          type="text"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Job Title -->
      <!-- Adddress Line One -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="address_line_one"
          name="Address Line One"
          :initial-value="investor.address_line_one"
          :endpoint="endpoint"
          type="text"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Address Line One -->
      <!-- Adddress Line Two -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="address_line_two"
          name="Address Line Two"
          :initial-value="investor.address_line_two"
          :endpoint="endpoint"
          type="text"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Address Line Two -->
      <!-- City -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="city"
          name="City"
          :initial-value="investor.city"
          :endpoint="endpoint"
          type="text"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / City -->
      <!-- Postcode -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="postcode"
          name="Postcode"
          :initial-value="investor.postcode"
          :endpoint="endpoint"
          type="text"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Postcode -->
      <!-- Country -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="country"
          name="Country"
          :initial-value="investor.country"
          :endpoint="endpoint"
          type="text"
          @updated="reloadInvestor"
        ></editable-field>
      </div>
      <!-- / Country -->
      <!-- Brand -->
      <div class="">
        <switch-datetime-field
          property-switch="brand_id"
          property-date="brand_changed_at"
          name="Brand"
          :initial-value-switch="investor.brand_id"
          :initial-value-date="investor.brand_changed_at"
          :endpoint="endpoint"
          off-label="Bags of Luxury"
          :on-label="brandOnLabel"
          :cannot-edit="investor.brand_changed_at !== null"
          @updated="reloadInvestor"
        ></switch-datetime-field>
      </div>
      <!-- / Brand -->
    </v-card-text>
  </base-card>
</template>

<script>
import EditableField from "../../../../../components/EditableField.vue";
import moment from 'moment/moment';
import SwitchDatetimeField from '@/components/SwitchDatetimeField.vue';
export default {
  props: ["investor"],
  components: { SwitchDatetimeField, EditableField },
  methods: {
    reloadInvestor() {
      this.$emit("reload");
    },
  },
  computed: {
    brandOnLabel() {
      return 'Maia' + (this.investor.brand_changed_at !== null ? ' since ' + moment(this.investor.brand_changed_at).format("Do MMMM YYYY") : '');
    },
    endpoint() {
      return "investors/" + this.investor.id;
    },
  },
};
</script>

<style scoped lang="scss">
.border-bottom {
  border-bottom: 1px solid #efecf3 !important;
}
</style>
