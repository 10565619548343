<template>
  <base-card>
    <v-card-title>Setup Details</v-card-title>
    <v-card-text>
      <!-- Account Setup-->
      <div class="border-bottom pb-3 mb-3">
        <b class="b-label">Account Setup</b>
        {{ investor.account_setup ? 'Yes' : 'No' }}
      </div>
      <!-- / Account Setup -->
      <!-- ID Verified-->
      <div class="border-bottom pb-3 mb-3">
        <b class="b-label">ID Verified</b>
        {{ investor.id_verified ? 'Yes' : 'No' }}
      </div>
      <!-- / ID Verified -->
      <!-- Approved-->
      <div class="">
        <b class="b-label">Approved</b>
        {{ investor.approved ? 'Yes' : 'No' }}
      </div>
      <!-- / Approved -->
    </v-card-text>
  </base-card>
</template>

<script>
import EditableField from "../../../../../components/EditableField.vue";
export default {
  props: ["investor"],
  components: { EditableField },
  methods: {
    reloadInvestor() {
      this.$emit("reload");
    },
  },
  computed: {
    endpoint() {
      return 'investors/' + this.investor.id;
    }
  }
};
</script>

<style scoped lang="scss">
.b-label {
  display: block;
  margin-bottom: 10px;
}

.border-bottom {
  border-bottom: 1px solid #efecf3 !important;
}
</style>