<template>
  <div>
    <p v-if="roi['xirr'] > 0" class="text-h5">{{ (roi['xirr'] * 100).toFixed(2) }}% <span class="text-small">per annum</span>
    </p>

  </div>
</template>

<script>
import Products from "../../mixins/Products";

export default {

  props: ["inventory"],
  mixins: [Products],
  data() {
    return {
      roi: this.inventory,
    };
  },
  computed: {},
};
</script>
