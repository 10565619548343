<template>
  <div>
    <v-data-table :headers="headers" :items="inventory" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                  :items-per-page="[5, 10, 50, 100]" :footer-props="{
    'items-per-page-options': [5, 10, 50, 100]
  }" >
    </v-data-table>
    <div>

    </div>

  </div>
</template>

<script>
import Products from '../../mixins/Products';
export default {
  props: ['inventory'],
  mixins: [Products],
  data() {
    return {
      pagination: {
        itemsPerPage: 5,
      },
      // length: Math.ceil(this.inventory.length / PAGE_LENGTH),
      sortBy: 'dateSort',
      sortDesc: false,
      onboarding: 0,
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'date',
        },
        {
          text: 'Stock Number',
          value: 'stock_number',
          sortable: false,
        },
        {
          text: 'Item',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: false,
        },
        {
          text: 'Debit(£)',
          value: 'purchase_amount',
          sortable: false,
        },
        {
          text: 'Credit (£)',
          value: 'sales_amount',
          sortable: false,
        },

        // {
        // text: "Balance",
        // value: "balance",
        // },
      ],
    };
  },
};

</script>
