import axios from 'axios';

export default {
    addFunds(accountId, amountInMinorUnits) {
        return axios.post(window.API_BASE + '/accounts/' + accountId + '/add-funds', {
            amount: amountInMinorUnits
        });
    },

    withdrawFunds(accountId, amountInMinorUnits) {
        return axios.post(window.API_BASE + '/accounts/' + accountId + '/withdraw-funds', {
            amount: amountInMinorUnits
        });
    },

    get(accountId) {
        return axios.get(window.API_BASE + '/accounts/' + accountId);
    }
}