<template>
  <div class="wrapper" :class="{ editing: isEditing }">
    <div class="left" :class="{ 'opacity-40': isSaving }">
      <!-- Not editing -->
      <div v-if="!isEditing">
        <label class="form-label text-bold">{{ name }}</label>
        <div>
          <span>
            {{ !!currentValueSwitch ? onLabel : offLabel }}
          </span>
        </div>
      </div>
      <!-- / Not editing -->

      <!-- Editing -->
      <div v-if="isEditing">
        <label class="form-label fw-bold">
          {{ name }}
        </label>
        <!-- Switch -->
        <div class="form-container">
          <v-switch v-model="newValueSwitch" :label="!!newValueSwitch ? onLabel : offLabel" hide-details></v-switch>
        </div>
        <!-- / Switch -->
        <!-- Date -->
        <div v-if="!!newValueSwitch" class="form-container date-container">
          <v-text-field type="date" v-model="newValueDate" class="form-control"></v-text-field>
        </div>
        <!-- / Date -->
      </div>
      <!-- / Editing -->
    </div>

    <!-- Not editing -->
    <div v-if="!isEditing && !cannotEdit" class="right" aria-label="Edit">
      <a @click.prevent="edit" class="nav-link py-0" href="#">
        <v-icon>mdi-pencil</v-icon>
      </a>
    </div>
    <!-- / Not editing -->

    <!-- Editing -->
    <div v-if="isEditing" class="actions-wrapper" aria-label="Save">
      <v-btn depressed :disabled="isSaving" class="danger--text me-2" color="lighten-5 danger" @click.prevent="cancel">
        Cancel
      </v-btn>

      <v-btn depressed class="primary--text" color="lighten-5 primary" :disabled="isSaving" :loading="isSaving"
             @click.prevent="attemptSave">
        Save
      </v-btn>
    </div>
    <!-- / Editing -->
  </div>
</template>

<script>
import axios from "axios";
import Forms from "@/mixins/Forms";
import Display from "@/mixins/Display";

export default {
  props: [
    "propertySwitch",
    "propertyDate",
    "name",
    "initialValueSwitch",
    "initialValueDate",
    "endpoint",
    "cannotEdit",
    "onLabel",
    "offLabel"
  ],
  mixins: [Forms, Display],
  data() {
    return {
      isEditing: false,
      currentValueSwitch: null,
      currentValueDate: null,

      newValueSwitch: null,
      newValueDate: null,

      isSaving: false,
      serverErrors: [],
    };
  },
  methods: {
    edit() {
      this.isEditing = true;
      this.newValueSwitch = this.initialValueSwitch;
      if (this.currentValueDate) {
        this.newValueDate = this.currentValueDate.split('T')[0];
      }
    },

    cancel() {
      this.isEditing = false;
      this.newValueSwitch = null;
      this.newValueDate = null;
    },

    attemptSave() {
      this.save();
    },

    save() {
      if (!this.newValueDate) {
        this.$toast.error("Date can't be empty");
        return;
      }

      this.serverErrors = [];
      this.isSaving = true;

      let params = {};
      params[this.propertySwitch] = this.newValueSwitch;
      params[this.propertyDate] = this.newValueDate;
      axios
        .put(window.API_BASE + "/" + this.endpoint, params)
        .then(() => {
          this.currentValueSwitch = this.newValueSwitch;
          this.currentValueDate = this.newValueDate;
          this.newValueSwitch = null;
          this.newValueDate = null;
          this.isSaving = false;
          this.isEditing = false;
          this.$toast.success(this.name + " saved successfully!");
          this.$emit("updated");
        })
        .catch((e) => {
          console.log(e);
          this.isSaving = false;
          this.setErrors(e, "Error saving new " + this.name);
          if (this.serverErrors.length) {
            this.$toast.error(this.serverErrors[0]);
          } else {
            this.$toast.error("Error saving new " + this.name);
          }
        });
    },
  },
  created() {
    this.currentValueSwitch = this.initialValueSwitch;
    this.currentValueDate = this.initialValueDate;
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.actions-wrapper {
  padding-top: 29px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    flex-grow: 1;
    padding-right: 20px;
  }

  .right {
    flex-shrink: 1;
  }

  label {
    display: block;
    padding-bottom: 5px;
  }

  .text-bold,
  .fw-bold {
    font-weight: 700;
  }
}

.form-container {
  display: inline-block;
}

.date-container {
  margin-left: 50px;
}

.btn.btn-sm {
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
}
</style>
