<template>
  <div>
    <!-- Add Funds -->
    <v-btn
      depressed
      color="primary"
      @click.prevent.native="showAddFundsModal = true"
      class="me-3"
    >
      <v-icon class="me-2">mdi-plus</v-icon>
      Add Funds</v-btn
    >

    <v-dialog v-model="showAddFundsModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Add Funds</v-card-title>

        <v-card-text>
          <v-form ref="addFundsForm" @submit.prevent="attemptAddFunds">
            <v-text-field
              :disabled="isAddingFunds"
              :label="`Amount (${account.currency})`"
              v-model="addFundsForm.amount"
              :rules="amountRules"
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isAddingFunds"
            color="red darken-1"
            text
            @click="showAddFundsModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isAddingFunds"
            :loading="isAddingFunds"
            color="primary darken-1"
            text
            @click="attemptAddFunds"
          >
            Add Funds
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Add Funds -->

    <!-- Widthdraw Funds -->
    <v-btn
      depressed
      color="dark"
      @click.prevent.native="showWidthdrawFundsModal = true"
      dark
    >
      <v-icon class="me-2">mdi-plus</v-icon>
      Withdraw Funds</v-btn
    >

    <v-dialog v-model="showWidthdrawFundsModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Withdraw Funds</v-card-title>

        <v-card-text>
          <v-form
            ref="withdrawFundsForm"
            @submit.prevent="attemptWithdrawFunds"
          >
            <v-text-field
              :disabled="isWithdrawingFunds"
              :label="`Amount (${account.currency})`"
              v-model="withdrawFundsForm.amount"
              :rules="amountRules"
              required
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isWithdrawingFunds"
            color="red darken-1"
            text
            @click="showWidthdrawFundsModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isWithdrawingFunds"
            :loading="isWithdrawingFunds"
            color="primary darken-1"
            text
            @click="attemptWithdrawFunds"
          >
            Withdraw Funds
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Withdraw Funds -->
  </div>
</template>

<script>
import accounts from "../../../../../../api/accounts";
import Forms from "../../../../../../mixins/Forms";
export default {
  props: ["investor", "account"],

  mixins: [Forms],

  data() {
    return {
      showAddFundsModal: false,
      isAddingFunds: false,
      addFundsForm: {
        amount: null,
      },

      showWidthdrawFundsModal: false,
      isWithdrawingFunds: false,
      withdrawFundsForm: {
        amount: null,
      },

      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => /^\d+(\.\d+)?$/.test(v) || "Must be a number",
        // (v) =>
        //   (v && v.toString().split(".")[0].length <= 5) ||
        //   "No more than 5 digits before the decimal point",
        (v) =>
          (v && v.toString().split(".").length < 2) ||
          (v && v.toString().split(".")[1].length <= 2) ||
          "No more than 2 digits after the decimal point",
      ],
    };
  },

  methods: {
    attemptAddFunds() {
      let isValid = this.$refs["addFundsForm"].validate();
      if (isValid) {
        this.addFunds();
      }
    },

    addFunds() {
      let vm = this;
      vm.isAddingFunds = true;
      accounts
        .addFunds(this.account.id, Number(this.addFundsForm.amount) * 100)
        .then((r) => {
          vm.isAddingFunds = false;
          vm.addFundsForm.amount = null;
          vm.showAddFundsModal = false;
          vm.$toast.success("Funds added successfully");
          vm.$emit("refresh");
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingFunds = false;
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Something went wrong. Please try again.";
          }
          vm.$toast.error(error);
        });
    },

    attemptWithdrawFunds() {
      let isValid = this.$refs["withdrawFundsForm"].validate();
      if (isValid) {
        this.withdrawFunds();
      }
    },

    withdrawFunds() {
      let vm = this;
      vm.isWithdrawingFunds = true;
      accounts
        .withdrawFunds(this.account.id, Number(this.withdrawFundsForm.amount) * 100)
        .then((r) => {
          vm.isWithdrawingFunds = false;
          vm.withdrawFundsForm.amount = null;
          vm.showWidthdrawFundsModal = false;
          vm.$toast.success("Funds withdrawn successfully");
          vm.$emit("refresh");
        })
        .catch((e) => {
          console.log(e);
          vm.isWithdrawingFunds = false;
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Something went wrong. Please try again.";
          }
          vm.$toast.error(error);
        });
    },
  },
};
</script>

<style>
</style>