<template>
  <div class="investors-show">
    <v-container fluid>
      <div class="welcome">
        <v-btn @click.prevent.native="goBack" icon color="blue">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <h1>{{ title }}</h1>
      </div>

      <!-- Do some loading thing here -->
      <div class="loading" v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <!-- / Do some loading thing here -->

      <!-- Main Details -->
      <v-row v-if="!isLoading && investor" class="mb-3">
        <v-col cols="12">
          <base-card class="overflow-hidden">
            <!-- No accounts to show -->
            <v-card-text class="color--red" v-if="!accounts.length">
              No accounts to show

              <div class="mt-3">
                <v-btn @click.prevent="showAddAccountModal = true" dark depressed small>
                  <v-icon small class="me-2">mdi-plus</v-icon>
                  Add Account
                </v-btn>
              </div>
            </v-card-text>
            <!-- / No accounts to show -->

            <v-dialog v-model="showAddAccountModal" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-h5 mb-4">Add Account</v-card-title>

                <v-card-text>
                  <v-form ref="form" @submit.prevent="attemptAddAccount">
                    <v-row>
                      <v-col :cols="12" :md="6">
                        <v-select :disabled="isAddingAccount" label="Currency" v-model="addAccountForm.currency"
                          :items="['GBP', 'EUR', 'USD']">

                        </v-select>
                      </v-col>
                      <v-col :cols="12" :md="6">
                        <v-text-field :disabled="isAddingAccount"
                          :prefix="currencyCodeToSymbol(addAccountForm.currency)" label="Amount"
                          v-model="addAccountForm.amount" :rules="amountRules" required />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col :cols="12" :md="6">
                        <v-select :disabled="isAddingAccount" label="Account Type" v-model="addAccountForm.type"
                          :items="accountTypeOptions">

                        </v-select>
                      </v-col>
                      <v-col :cols="12" :md="6">
                        <v-select :disabled="isAddingAccount" label="Payout Frequency"
                          v-model="addAccountForm.payout_frequency" :items="payoutFrequencyOptions">

                        </v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="isAddingAccount" color="red darken-1" text @click="showAddAccountModal = false">
                    Cancel
                  </v-btn>
                  <v-btn :disabled="isAddingAccount" :loading="isAddingAccount" color="primary darken-1" text
                    @click="attemptAddAccount">
                    Add Account
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- <v-card-title style="border-bottom: 1px solid #eee;">Accounts</v-card-title> -->
            <v-tabs v-model="selected_tab" v-if="accounts.length">
              <v-tab v-for="account in accounts" :key="account.id">{{ account.currency }} Account</v-tab>
              <!-- <v-tab @click.native.prevent.stop.capture="showAddAccountModal = true">+ Add Account</v-tab> -->
            </v-tabs>
            <v-card-text v-if="selectedAccount" :key="`account-tab-${selectedAccount.id}`">
              <selected-account-details @refresh="reloadSilent" :investor="investor" :account="selectedAccount">
              </selected-account-details>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
      <!-- / Main Details -->

      <v-row v-if="investor">
        <v-col :cols="12" :md="6">
          <!-- Personal Details -->
          <personal-details :investor="investor" @reload="reloadSilent"></personal-details>
          <!-- / Personal Details -->
        </v-col>
        <v-col :cols="12" :md="6">
          <!-- Personal Details -->
          <setup-details :investor="investor" @reload="reloadSilent"></setup-details>
          <!-- / Personal Details -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import investors from "../../../../api/investors";
import PersonalDetails from "./Show/PersonalDetails.vue";
import Display from "../../../../mixins/Display";
import Forms from "../../../../mixins/Forms";
import SelectedAccountDetails from "./Show/SelectedAccountDetails.vue";
import SetupDetails from "./Show/SetupDetails.vue";

const PAYOUT_FREQUENCY_OPTIONS = [
  {
    value: 0,
    text: "Quarterly",
  },
  {
    value: 1,
    text: "Yearly",
  },
  {
    value: 2,
    text: "On Request",
  },
];

const ACCOUNT_TYPE_OPTIONS = [
  {
    value: 0,
    text: "Discretionary"
  },
  {
    value: 1,
    text: "Part Discretionary"
  },
  {
    value: 2,
    text: "Approval Required"
  }
];

export default {
  components: { PersonalDetails, SelectedAccountDetails, SetupDetails },

  data() {
    return {
      isLoading: false,
      investor: null,
      selectedAccountId: null,

      showAddAccountModal: false,
      isAddingAccount: false,
      addAccountForm: {
        currency: 'GBP',
        amount: null,
        payout_frequency: 2,
        type: 0
      },

      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => /^\d+(\.\d+)?$/.test(v) || "Must be a number",
        // (v) =>
        //   (v && v.toString().split(".")[0].length <= 5) ||
        //   "No more than 5 digits before the decimal point",
        (v) =>
          (v && v.toString().split(".").length < 2) ||
          (v && v.toString().split(".")[1].length <= 2) ||
          "No more than 2 digits after the decimal point",
      ],

      payoutFrequencyOptions: PAYOUT_FREQUENCY_OPTIONS,
      accountTypeOptions: ACCOUNT_TYPE_OPTIONS
    };
  },

  mixins: [Display, Forms],

  created() {
    this.loadInvestor();
  },

  methods: {
    goBack() {
      return this.$router.push("/investors");
    },

    attemptAddAccount() {
      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.addAccount();
      }
    },

    addAccount() {
      let vm = this;
      vm.isAddingAccount = true;

      let params = {
        currency: this.addAccountForm.currency,
        amount: Number(this.addAccountForm.amount) * 100,
        payout_frequency: this.addAccountForm.payout_frequency,
        type: this.addAccountForm.type
      };

      investors.addAccount(this.investor.id, params).then(r => {
        vm.isAddingAccount = false;
        vm.addAccountForm.amount = null;
        vm.showAddAccountModal = false;
        vm.loadInvestor();
      }).catch(e => {
        console.log(e);
        vm.isAddingAccount = false;

        let errors = vm.getErrorsAsArray(e);
        if (errors.length) {
          vm.$toast.error(_.first(errors));
        } else {
          vm.$toast.error("Error adding account");
        }
      })
    },

    loadInvestor() {
      let vm = this;
      vm.isLoading = true;
      vm.investor = null;
      investors
        .get(this.investorId)
        .then((r) => {
          vm.isLoading = false;
          vm.investor = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.$toast.error("Consignor not found");
          vm.$router.push("/investors");
        });
    },

    reloadSilent() {
      let vm = this;
      // vm.isLoading = true;
      investors
        .get(this.investorId)
        .then((r) => {
          // vm.isLoading = false;
          vm.investor = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.$toast.error("Consignor not found");
          vm.$router.push("/investors");
        });
    },
  },

  computed: {
    investorId() {
      return this.$route.params.id;
    },

    endpoint() {
      return "investors/" + this.investorId;
    },

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.investor) {
        return this.investor.name;
      }
      return "Unknown";
    },

    accounts() {
      if (this.investor && this.investor.accounts) {
        return this.investor.accounts;
      }
      return [];
    },

    selectedAccount() {
      return _.find(this.accounts, { id: this.selectedAccountId });
    },

    selected_tab: {
      get() {
        if (this.selectedAccount) {
          return this.accounts.indexOf(this.selectedAccount);
        }
        return null;
      },
      set(val) {
        let account = this.accounts[val];
        if (account) {
          this.selectedAccountId = account.id;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
}

.investors-show {
  padding: 20px;

  .welcome {
    margin-bottom: 20px;
    display: flex;
    justify-items: center;

    h1 {
      margin-left: 10px;
    }
  }
}
</style>