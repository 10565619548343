<template>
  <v-row>
    <v-col :cols="12" :md="4">
      <!-- Balance -->
      <!-- <div class="pb-3 mb-3 border-bottom">
        <b class="b-title">Cash Balance</b>
        {{ formatCostInPence2dp(account.balance, account.currency) }}
      </div> -->
      <!-- / Balance -->

      <!-- Account Name -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field property="payout_account_name" name="Payout Account Name"
          :initial-value="account.payout_account_name" :endpoint="`accounts/${account.id}`" type="text"
          @updated="refresh"></editable-field>
      </div>
      <!-- / Account Name -->
      <!-- Sort Code -->
      <div class="border-bottom pb-3 mb-3">
        <editable-field property="payout_sort_code" name="Payout Sort Code" :initial-value="account.payout_sort_code"
          :endpoint="`accounts/${account.id}`" type="text" @updated="refresh"></editable-field>
      </div>
      <!-- / Sort Code -->
      <!-- Account Number -->
      <div class="border-bottom pb-3 mb-3">
        <!-- <div class="pb-3 mb-3"> -->
        <editable-field property="payout_account_number" name="Payout Account Number"
          :initial-value="account.payout_account_number" :endpoint="`accounts/${account.id}`" type="text"
          @updated="refresh"></editable-field>
      </div>
      <!-- / Account Number -->
      <!-- Account Number -->
      <!-- <div class="border-bottom pb-3 mb-3"> -->
      <!-- / Account Number -->

      <!-- Total Consignors Bag Sales -->
      <div class="pb-3 mb-3 border-bottom">
        <b class="b-title">Total Bag Sales</b>
        {{
          formatCostInPence2dp(
            account.total_bag_sales,
          account.currency
                )
        }}
      </div>
      <!-- / Total Consignors Bag Sales -->

      <!-- Total Purchase Price of Bags Sold -->
      <div class="pb-3 mb-3 border-bottom">
        <b class="b-title">Total Purchase Price of Bags Sold</b>
        {{
          formatCostInPence2dp(
            account.total_purchase_price_of_bags_sold,
          account.currency
                )
        }}
      </div>
      <!-- / Total Purchase Price of Bags Sold -->

      <!-- Commission Paid -->
      <div class="pb-3 mb-3 border-bottom">
        <b class="b-title">Consignor Commission Paid From Sales</b>
        {{
          formatCostInPence2dp(
            (account.total_bag_sales - account.total_purchase_price_of_bags_sold) / 2,
            account.currency
          )
        }}
      </div>
      <!-- / Commission Paid -->

      <!-- Current Inventory Balance -->
      <div class="pb-3 mb-3">
        <b class="b-title">Total Purchase Price of Current Inventory</b>
        {{
          formatCostInPence2dp(
            account.current_inventory_balance,
          account.currency
                )
        }}
      </div>
      <!-- / Total Purchase Price of Current Stock -->

      <div class="pb-3 mb-3">
        <b class="b-title">Rate of Return (XIRR)</b>
        <investment-xirr :inventory="itemXirr"></investment-xirr>
      </div>

      <!-- / Current Inventory Balance -->


      <!-- Payout Frequency -->
      <!-- <div class="border-bottom pb-3 mb-3">
        <editable-field
          property="payout_frequency"
          name="Payout Frequency"
          :initial-value="account.payout_frequency"
          :endpoint="`accounts/${account.id}`"
          type="select"
          :options="payoutFrequencyOptions"
          @updated="refresh"
        ></editable-field>
      </div> -->
      <!-- / Payout Frequency -->

      <!-- Account Type -->
      <!-- <div class="pb-3 mb-3">
        <editable-field
          property="type"
          name="Account Type"
          :initial-value="account.type"
          :endpoint="`accounts/${account.id}`"
          type="select"
          :options="accountTypeOptions"
          @updated="refresh"
        ></editable-field>
      </div> -->
      <!-- / Account Type -->

      <!-- Actions -->
      <div class="actions-wrapper">
        <!-- <account-actions
          :investor="investor"
          :account="account"
          @refresh="refresh"
        ></account-actions> -->
      </div>
      <!-- / Actions -->
    </v-col>
    <v-col :cols="12" :md="4" class="px-10">
      <b class="b-title">Current Inventory</b>
      <inventory-gallery :inventory="currentInventory"></inventory-gallery>
    </v-col>
    <v-col :cols="12" :md="4" class="px-10">
      <b class="b-title">Past Inventory</b>
      <inventory-gallery :inventory="pastInventory"></inventory-gallery>
    </v-col>
  </v-row>
</template>

<script>
import Display from "../../../../../mixins/Display";
import EditableField from "../../../../../components/EditableField.vue";
import AccountActions from "./SelectedAccountDetails/AccountActions.vue";
import InventoryGallery from "../../../../../components/gallery/InventoryGallery.vue";
import InvestmentTable from '../../../../../components/Account/InvestmentTable.vue';
import InvestmentXirr from '../../../../../components/Account/InvestmentXirr.vue';
import { xirr, CashFlow } from '@webcarrot/xirr';

const BLANK_GRID = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

const PAYOUT_FREQUENCY_OPTIONS = [
  {
    value: 0,
    text: "Quarterly",
  },
  {
    value: 1,
    text: "Yearly",
  },
  {
    value: 2,
    text: "On Request",
  },
];

const ACCOUNT_TYPE_OPTIONS = [
  {
    value: 0,
    text: "Discretionary"
  },
  {
    value: 1,
    text: "Part Discretionary"
  },
  {
    value: 2,
    text: "Approval Required"
  }
];

export default {
  props: ["investor", "account"],
  mixins: [Display],
  data() {
    return {
      payoutFrequencyOptions: PAYOUT_FREQUENCY_OPTIONS,
      accountTypeOptions: ACCOUNT_TYPE_OPTIONS
    };
  },
  components: {  InvestmentTable, InvestmentXirr, EditableField, AccountActions, InventoryGallery },
  computed: {
    itemSales() {
      if (this.selectedAccount && this.selectedAccount.products) {
        const stringify = JSON.stringify(this.selectedAccount.products);
        const purchases = JSON.parse(stringify);
        const sales = JSON.parse(stringify);

        for (let i = 0; i < purchases.length; i++) {
          purchases[i]['sold_at'] = '';
          purchases[i]['sale_price'] = '';
          purchases[i]['dateSort'] = purchases[i]['purchased_at'];
          purchases[i]['date'] = purchases[i]['purchased_at'].substring(0, 10).split("-").reverse().join("-");
          purchases[i]['type'] = 'Purchase';
          purchases[i]['purchase_amount'] = (purchases[i]['purchase_price'] / 100).toFixed(2);
        }

        const lines = _.filter(sales, (product) => {
          return product.status == 3;
        });

        for (let i = 0; i < lines.length; i++) {
          lines[i]['purchased_at'] = '';
          lines[i]['date'] = lines[i]['sold_at'].substring(0, 10).split("-").reverse().join("-");
          lines[i]['type'] = 'Sale';
          lines[i]['dateSort'] = lines[i]['sold_at'];
          lines[i]['sales_amount'] = (lines[i]['purchase_price'] / 100 + lines[i]['consignor_profit'] / 100).toFixed(2);
          lines[i]['purchase_price'] = '';
        }

        const transactions = purchases.concat(lines);
        const sortedTransactions = transactions.map(({ amount, date }) => ({ amount, date }));

        return transactions.length ? transactions : BLANK_GRID;
      }
    },

    itemXirr() {

        const stringify = JSON.stringify(this.account.products);
        const purchases = JSON.parse(stringify);
        const sales = JSON.parse(stringify);
        var roi = [];

        const linesP = purchases;
        for (let i = 0; i < linesP.length; i++) {
          linesP[i]['sold_at'] = '';
          linesP[i]['sale_price'] = '';
          linesP[i]['dateSort'] = linesP[i]['purchased_at'];
          linesP[i]['date'] = new Date(linesP[i]['purchased_at']);
          linesP[i]['type'] = 'Purchase';
          linesP[i]['amount'] = -(linesP[i]['purchase_price'] / 100);
        }

        const unsold = _.filter(purchases, (item) => {
          return item.status !== 3;
        });

        const unsoldStringify = JSON.stringify(unsold)
        const unsoldP = JSON.parse(unsoldStringify);
        for (let i = 0; i < unsoldP.length; i++) {
          unsoldP[i]['sold_at'] = '';
          unsoldP[i]['sale_price'] = '';
          unsoldP[i]['date'] = new Date();
          unsoldP[i]['type'] = 'Sale';
          unsoldP[i]['amount'] = (unsoldP[i]['purchase_price'] / 100);
        }

        const lines = _.filter(sales, (product) => {
          return product.status == 3;
        });
        for (let i = 0; i < lines.length; i++) {
          lines[i]['purchased_at'] = '';
          if (new Date(lines[i]['sold_at']) > "0") {
            lines[i]['date'] = new Date(lines[i]['sold_at'])
          } else {
            lines[i]['date'] = new Date();
          }
          lines[i]['type'] = 'Sale';
          lines[i]['dateSort'] = lines[i]['sold_at'];
          lines[i]['amount'] = (lines[i]['purchase_price'] / 100 + lines[i]['consignor_profit'] / 100);
          lines[i]['purchase_price'] = '';
        }

        const transactions = linesP.concat(lines).concat(unsoldP);
        console.log(JSON.stringify(transactions))
      const flows =
          transactions.map(({amount, date}) => ({amount, date}));
      console.log(JSON.stringify(flows))
        try {
          roi['xirr'] = xirr(flows);

          roi['start'] = transactions[0]['dateSort'].substring(0, 10);
          roi['end'] = transactions.pop()['dateSort'].substring(0, 10);
          console.log(xirr(flows))
        } catch (err) {
          roi['xirr'] = '0';
        }



      return roi;
    },
    endpoint() {
      return "accounts/" + this.account.id;
    },

    currentInventory() {
      if (this.account && this.account.products) {
        let products = _.filter(this.account.products, (product) => {
          return product.status !== 3;
        });
        return products.length ? products : BLANK_GRID;
      }
      return BLANK_GRID;
    },

    pastInventory() {
      if (this.account && this.account.products) {
        let products = _.filter(this.account.products, (product) => {
          return product.status == 3;
        });
        return products.length ? products : BLANK_GRID;
      }
      return BLANK_GRID;
    },

  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped lang="scss">
.b-title {
  display: block;
  padding-bottom: 5px;
}

.border-bottom {
  border-bottom: 1px solid #efecf3 !important;
}

.actions-wrapper {
  padding-top: 30px;
  padding-bottom: 10px;
}
</style>
