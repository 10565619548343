<template>
  <div>
    <v-window>
      <v-window-item>
        <v-row dense>
          <v-col cols="4" v-for="(item, i) in inventory" :key="i">
            <base-card>
              <v-img
                contain
                :class="{'clickable': item.id}"
                @click.native.prevent="() => selectProduct(item)"
                :src="getProductPrimaryImage(item)"
                gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.05)"
                height="100px"
              ></v-img>
            </base-card>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <v-card-actions class="justify-space-between px-0">
      <v-btn text @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-item-group v-model="onboarding" class="text-center" mandatory>
        <v-item
          v-for="n in length"
          :key="`btn-${n}`"
          v-slot="{ active, toggle }"
        >
          <v-btn :input-value="active" icon @click="toggle">
            <v-icon>mdi-record</v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-btn text @click="next">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import Products from "../../mixins/Products";
const PAGE_LENGTH = 9;
export default {
  props: ["inventory"],
  mixins: [Products],
  data() {
    return {
      length: Math.ceil(this.inventory.length / PAGE_LENGTH),
      onboarding: 0,
    };
  },
  computed: {},
  methods: {
    next() {
      this.onboarding =
        this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
    },
    selectProduct(product) {
      if (product.id !== undefined) {
        this.$router.push('/products/' + product.id);
      }
    }
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>