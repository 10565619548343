<template>
  <div class="wrapper" :class="{ editing: isEditing }">
    <div class="left" :class="{ 'opacity-40': isSaving }">
      <!-- Not editing -->
      <div v-if="!isEditing">
        <label class="form-label text-bold">{{ name }}</label>
        <div v-if="type === 'password'">***********</div>
        <div v-else>
          <span v-if="
            type !== 'textarea' && type !== 'select' && type !== 'currency' && type !== 'date' && type !== 'switch' && property !== 'sales_number' && property !== 'country_of_export'
          ">
            {{ currentValue? currentValue: "Not provided" }}
          </span>
          <span v-if="type == 'date'">
            {{ currentValue? dateFormatted: "Not provided" }}
          </span>
          <span v-if="property == 'sales_number'">
            {{ currentValue? currentValue: "N/A" }}
          </span>
          <span v-if="property == 'country_of_export'">
            {{ currentValue? currentValue: "Not Exported" }}
          </span>
          <span v-if="type == 'currency'">
            {{
  currentValue
  ? formatCostInPence(currentValue, this.currency)
            : "-"
            }}
          </span>
          <span v-if="type == 'select'">
            {{ selectedOption }}
          </span>
          <span v-if="type == 'switch'">
            {{ !!currentValue ? onLabel : offLabel }}
          </span>
          <span class="textarea-display" v-if="type === 'textarea'" v-html="currentValue"></span>
        </div>
      </div>
      <!-- / Not editing -->

      <!-- Editing && Confirm Current -->
      <div v-if="isEditing && confirmCurrent" class="mb-4">
        <label class="form-label fw-bold"> Current {{ name }} </label>
        <v-text-field @keyup.enter="attemptSave" :type="type" v-model="currentConfirmationValue"
          class="form-control"></v-text-field>
      </div>
      <!-- / Editing && Confirm Previous -->

      <!-- Editing -->
      <div v-if="isEditing">
        <label class="form-label fw-bold">
          {{ confirmPrevious? "New ": null }}
          {{ name }}
        </label>
        <v-text-field @keyup.enter="attemptSave" :type="type" v-model="newValue" class="form-control"
          v-if="type !== 'textarea' && type !== 'select' && type !=='switch'"></v-text-field>
        <v-textarea v-if="type === 'textarea'" v-model="newValue" class="form-control" rows="5"></v-textarea>
        <!-- Select -->
        <v-select v-if="type === 'select'" v-model="newValue" :items="options" class="form-control">
        </v-select>
        <!-- / Select -->
        <!-- Switch -->
        <v-switch v-if="type === 'switch'" v-model="newValue" :label="!!newValue ? onLabel : offLabel" hide-details></v-switch>
        <!-- / Switch -->
      </div>
      <!-- / Editing -->

      <!-- Editing && Confirmation Required -->
      <div class="mt-4" v-if="isEditing && confirmed === true">
        <label class="form-label fw-bold">
          {{ confirmPrevious? "New ": null }}
          {{ name }} Confirmation
        </label>
        <v-text-field @keyup.enter="attemptSave" :type="type" v-model="newValueConfirmation"
          class="form-control"></v-text-field>
      </div>
      <!-- / Editing & Confirmation Required -->
    </div>

    <!-- Not editing -->
    <div v-if="!isEditing && !cannotEdit" class="right" aria-label="Edit">
      <a @click.prevent="edit" class="nav-link py-0" href="#">
        <v-icon>mdi-pencil</v-icon>
      </a>
    </div>
    <!-- / Not editing -->

    <!-- Editing -->
    <div v-if="isEditing" class="actions-wrapper" aria-label="Save">
      <v-btn depressed :disabled="isSaving" class="danger--text me-2" color="lighten-5 danger" @click.prevent="cancel">
        Cancel
      </v-btn>

      <v-btn depressed class="primary--text" color="lighten-5 primary" :disabled="isSaving" :loading="isSaving"
        @click.prevent="attemptSave">
        Save
      </v-btn>
    </div>
    <!-- / Editing -->
  </div>
</template>

<script>
import axios from "axios";
import Forms from "@/mixins/Forms";
import Display from "@/mixins/Display";
import moment from 'moment';

export default {
  props: [
    "property",
    "name",
    "initialValue",
    "endpoint",
    "type",
    "confirmed",
    "confirmCurrent",
    "options",
    "currency",
    "cannotEdit",
    "onLabel",
    "offLabel"
  ],
  mixins: [Forms, Display],
  data() {
    return {
      isEditing: false,
      currentValue: null,

      currentConfirmationValue: null,
      newValue: null,
      newValueConfirmation: null,

      isSaving: false,
      serverErrors: [],
    };
  },
  methods: {
    edit() {
      this.isEditing = true;
      if (!this.confirmCurrent) {
        this.newValue = this.currentValue;
      }
      this.currentConfirmationValue = null;
      this.newValueConfirmation = null;
      if (this.type == "currency" && this.newValue) {
        this.newValue = this.newValue / 100;
      }
      if (this.type == "date" && this.newValue) {
        this.newValue = this.newValue.split('T')[0]
      }
    },

    cancel() {
      this.isEditing = false;
      this.newValue = null;
      this.currentConfirmationValue = null;
      this.newValueConfirmation = null;
    },

    attemptSave() {
      this.save();
    },

    save() {
      this.serverErrors = [];
      this.isSaving = true;

      let params = {};
      params[this.property] = this.newValue;
      if (this.confirmed) {
        params[this.property + "_confirmation"] = this.newValueConfirmation;
      }
      if (this.confirmCurrent) {
        params["current_" + this.property] = this.currentConfirmationValue;
      }
      if (this.type == "currency") {
        params[this.property] = params[this.property] * 100;
      }
      axios
        .put(window.API_BASE + "/" + this.endpoint, params)
        .then(() => {
          this.currentValue = this.newValue;
          if (this.currentValue && this.type == "currency") {
            this.currentValue = this.currentValue * 100;
          }
          this.newValue = null;
          this.isSaving = false;
          this.isEditing = false;
          this.$toast.success(this.name + " saved successfully!");
          this.$emit("updated");
        })
        .catch((e) => {
          console.log(e);
          this.isSaving = false;
          this.setErrors(e, "Error saving new " + this.name);
          if (this.serverErrors.length) {
            this.$toast.error(this.serverErrors[0]);
          } else {
            this.$toast.error("Error saving new " + this.name);
          }
        });
    },
  },
  created() {
    this.currentValue = this.initialValue;
  },
  computed: {
    selectedOption() {
      let option = _.find(this.options, {
        value: this.currentValue,
      });
      if (option) {
        return option.text;
      }
      return "Not provided";
    },

    dateFormatted() {
      let dateMoment = moment(this.currentValue);
      if (dateMoment.isValid()) {
        return dateMoment.format("Do MMMM YYYY");
      }
      return null;
    }
  },
};
</script>

<style scoped lang="scss">
.actions-wrapper {
  padding-top: 29px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    flex-grow: 1;
    padding-right: 20px;
  }

  .right {
    flex-shrink: 1;
  }

  label {
    display: block;
    padding-bottom: 5px;
  }

  .text-bold,
  .fw-bold {
    font-weight: 700;
  }
}

.btn.btn-sm {
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;
}

.textarea-display {
  display: block;
  white-space: pre-line;
  padding-top: 10px;
}
</style>
